import React, { useEffect } from 'react';
import { CModal } from '@coreui/react';
import Countdown, { zeroPad } from 'react-countdown';
import moment from 'moment-timezone';
import { useTranslation } from 'react-i18next';
import LoadingIndicator from '../../loading/LoadingIndicator';
import PrimaryOrderButton from '../../buttons/PrimaryOrderButton';
import useHelpers from '../../../hooks/useHelpers';
import LoadingIndicatorPurple from '../../../resources/assets/animations/LoadingIndicatorPurple.json';
import CheckmarkIcon from '../../../resources/assets/icons/CheckmarkIcon';

export default function OrderPickupTimeOptimizationPendingModal({
  order,
  toggleModal,
}) {
  const { t } = useTranslation(null, { keyPrefix: 'Components:Modals:Delivery:OrderPickupTimeOptimizationPendingModal' });

  const { formatTime } = useHelpers();

  useEffect(() => {
    setTimeout(() => toggleModal(), 10000);
  }, []);

  return (
    <CModal
      visible
      alignment="center"
      scrollable
      size="xl"
      onClose={() => toggleModal()}
      className="tw-z-[1100]"
    >
      <div className="tw-p-medium">
        <h5 className="tw-text-center">
          #
          {order.number}
          {' '}
          {order.user.name}
        </h5>
        {order.is_pickup_time_optimized ? (
          <div className="tw-mx-auto tw-mt-small tw-flex tw-h-[200px] tw-w-[200px] tw-rounded-full tw-border-[20px] tw-border-solid tw-border-brand-primary">
            <CheckmarkIcon className="tw-w-16 tw-m-auto" />
          </div>
        ) : (
          <div className="tw-relative tw-mt-small">
            <LoadingIndicator
              width={300}
              height={300}
              src={LoadingIndicatorPurple}
              className="tw-mx-auto"
            />
            <Countdown
              date={moment().add(90, 'seconds').toDate()}
              key={`order-${order.id}-pickup-time-optimizer-timer`}
              renderer={({ minutes, seconds }) => (
                <h3 className="tw-absolute tw-top-1/2 tw-left-1/2 -tw-translate-x-1/2 -tw-translate-y-1/2 tw-text-shopify">
                  {zeroPad(minutes * 60 + seconds)}
                </h3>
              )}
            />
          </div>
        )}
        {order.is_pickup_time_optimized ? (
          <h5 className="tw-mt-small tw-text-center">{t('text.driver_found', { time: formatTime(order.estimated_pickup_time) })}</h5>
        ) : (
          <>
            <h5 className="tw-mt-small tw-text-center">{t('text.looking_for_driver')}</h5>
            <p className="tw-mt-small tw-text-center tw-text-shopify">{t('text.receipt')}</p>
          </>
        )}
        <PrimaryOrderButton
          order={order}
          title={t('buttons.ok')}
          className="tw-mt-medium tw-w-full"
          onClick={() => toggleModal()}
        />
      </div>
    </CModal>
  );
}
