import React, { useEffect, useMemo, useState } from 'react';
import {
  CFormLabel,
  CFormSelect, CFormTextarea, CInputGroup,
} from '@coreui/react';
import { useTranslation } from 'react-i18next';
import tw from 'twin.macro';
import collect from 'collect.js';
import CurrencyInput from 'react-currency-input-field';
import { useSelector } from 'react-redux';
import { REFUND_REASONS } from '../../../services/exports/Constants';
import Button, { Types, Variants } from '../../buttons/Button';
import Divider from '../../appearence/Divider';
import useFeedbackHandler from '../../../hooks/useFeedbackHandler';
import RefundsManager from '../../../services/api/RefundsManager';
import useHelpers from '../../../hooks/useHelpers';

export default function CreateRefundModal({
  payment,
  toggle,
  onRefund,
}) {
  const { t } = useTranslation(null, { keyPrefix: 'Components:Modals:DineIn:CreateRefundModal' });

  const { company } = useSelector((state) => state.company);

  const { formatCurrency } = useHelpers();
  const { setFeedback, resetFeedback, renderFeedbackLabel } = useFeedbackHandler();

  const [amount, setAmount] = useState(0);
  const [reason, setReason] = useState(REFUND_REASONS.RequestedByCustomer);
  const [manualReason, setManualReason] = useState('');
  const [loading, setLoading] = useState(false);

  const availableAmount = useMemo(
    () => Math.round(
      (payment.total_amount - collect(payment?.refunds).sum('total_amount') + Number.EPSILON) * 100,
    ) / 100,
    [payment],
  );

  const refundDisabled = useMemo(
    () => loading
      || !availableAmount
      || !amount
      || amount > availableAmount,
    [loading, amount, availableAmount],
  );

  useEffect(
    () => observeAmount(),
    [amount, availableAmount],
  );

  function observeAmount() {
    if (+amount === 0) {
      return resetFeedback();
    }

    if (amount > availableAmount) {
      return setFeedback({
        type: 'warning',
        message: t('feedback.amount_too_big', {
          amount: formatCurrency(availableAmount),
        }),
      });
    }

    return resetFeedback();
  }

  async function refund() {
    setLoading(true);
    const { success, data } = await RefundsManager.create(payment?.id, {
      amount,
      reason: reason === REFUND_REASONS.Other ? manualReason : reason,
    });
    setLoading(false);

    if (!success) {
      return setFeedback({
        message: data?.message,
        type: 'error',
        errors: data?.errors,
      });
    }

    toggle();
    return onRefund(payment);
  }

  const reasons = [
    {
      label: t('form.reason.options.requested_by_customer'),
      value: REFUND_REASONS.RequestedByCustomer,
    },
    {
      label: t('form.reason.options.reclamation'),
      value: REFUND_REASONS.Reclamation,
    },
    {
      label: t('form.reason.options.communication_error'),
      value: REFUND_REASONS.CommunicationError,
    },
    {
      label: t('form.reason.options.other'),
      value: REFUND_REASONS.Other,
    },
  ];

  return (
    <Backdrop>
      <Container>
        <h5>{t('title')}</h5>
        <div className="website-text mt-2">
          {t('description', {
            amount: formatCurrency(availableAmount),
          })}
        </div>
        <Divider className="my-3" />
        {renderFeedbackLabel}
        <div className="d-flex mb-2">
          <CFormLabel
            htmlFor="amount"
            className="my-auto mr-2"
          >
            {t('form.amount.label')}
          </CFormLabel>
          <CurrencyInput
            id="amount"
            value={amount}
            defaultValue="0,00"
            onValueChange={setAmount}
            decimalsLimit={2}
            allowNegativeValue={false}
            decimalSeparator="."
            intlConfig={{ locale: 'en-IN', currency: company?.currency }}
            className="w-100 rounded py-1 px-3"
          />
        </div>
        <div className="d-flex mt-2">
          <CFormLabel
            htmlFor="reason"
            className="my-auto mr-2"
          >
            {t('form.reason.label')}
          </CFormLabel>
          <CFormSelect
            id="reason"
            options={reasons}
            value={reason}
            onChange={(e) => setReason(e.target.value)}
            className="w-100"
          />
        </div>
        {reason === REFUND_REASONS.Other && (
          <div className="mt-2">
            <CFormLabel htmlFor="manual-reason">{t('form.manual_reason.label')}</CFormLabel>
            <CFormTextarea
              id="manual-reason"
              value={manualReason}
              rows={4}
              maxLength={255}
              resize={false}
              autoFocus
              onChange={(e) => setManualReason(e.target.value)}
            />
          </div>
        )}
        <Divider className="my-3" />
        <div className="d-flex justify-content-end">
          <Button
            title={t('buttons.cancel')}
            onClick={toggle}
            disabled={loading}
            className="mr-2"
            type={Types.info}
            variant={Variants.outline}
          />
          <Button
            title={t('buttons.refund')}
            onClick={refund}
            loading={loading}
            disabled={refundDisabled}
            type={Types.info}
          />
        </div>
      </Container>
    </Backdrop>
  );
}

const Backdrop = tw.div`fixed flex h-screen w-full top-0 left-0 bg-black bg-opacity-75 p-5`;
const Container = tw.div`m-auto bg-white p-4 rounded-lg`;
