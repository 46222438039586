import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CModalFooter } from '@coreui/react';
import Button from '../../../buttons/Button';
import OrderFulfillmentsManager from '../../../../services/api/OrderFulfillmentsManager';
import { FULFILLMENT_STATUSES } from '../../../../services/exports/Constants';

export default function ScheduledOrderStartPreparation({
  order,
  onOrderPreparing,
  onError,
}) {
  const { t } = useTranslation(null, { keyPrefix: 'Components:Modals:OrderDetails:Footers:ScheduledOrderStartPreparation' });

  const [loading, setLoading] = useState(false);

  async function startPreparation() {
    setLoading(true);
    const { success, data } = await OrderFulfillmentsManager.update(order.fulfillment_id, {
      status: FULFILLMENT_STATUSES.preparing,
    });
    setLoading(false);

    if (!success) {
      return onError(data?.message);
    }

    return onOrderPreparing(data.data);
  }

  return (
    <CModalFooter>
      <Button
        title={(
          <div className="website-text bold white p-1">
            {t('buttons.start')}
          </div>
        )}
        onClick={startPreparation}
        className="w-100 bg-orange"
        loading={loading}
      />
    </CModalFooter>
  );
}
